import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import animation1 from '../mobile-animations/nivo1_bg.json';
import grinchanimation from '../animations/grinch.json';
import Lottie from 'lottie-web';

import { controlOptions, scoreState, timerState, positionTimerState } from './atom';
import { SPEED_STEP, SPAWN_INTERVAL } from './constants';
import Control from './Control';
import Score from './Score';
import Timer from './Timer';

export default ({ currentSession, updateLevel, isLoading, currentLevel, handleNextLevel, endScreen, okitiJelkuOpen, okitiJelkuTimer }) => {
    const animationContainer = useRef(null);
    const grinchAnimationContainer = useRef(null);
    const directionIndicator = useRef(null);
    const powerIndicator = useRef(null);
    const ballRef = useRef(null);
    const hitGrid = useRef(null);
    const gridRow = useRef(null);
    const gridCell = useRef(null);
    const [lottieAnim1, setLottieAnim1] = useState([]);
    const [controlState, setControlState] = useRecoilState(controlOptions);
    const [score, setScore] = useRecoilState(scoreState);
    const [timer, setTimer] = useRecoilState(timerState);
    const [positionTimer, setPositionTimer] = useRecoilState(positionTimerState);
    const requestRef = useRef();
    const timerRef = useRef();
    const positionTimerRef = useRef();
    const [rowCellArray, setRowCellArray] = useState([]);
    const [gameStateCheck, setGameStateCheck] = useState(false);
    const [itterations, setItterations] = useState(1);
    const [multiplierState, setMultiplierState] = useState(false);
    const [isMultiplierPlayed, setIsMultiplierPlayed] = useState(false);
    const [isMultiplierReady, setIsMultiplierReady] = useState(false);
    const [gameStateChecker, setGameStateChecker] = useState(false);
    const [slotIndex, setSlotIndex] = useState(0);
    const [direction, setDirection] = useState(0.5);
    const [power, setPower] = useState(1.5);
    const [stateCheck, setStateCheck] = useState(false);
    const [defendGoal, setDefendGoal] = useState(false);
    // const [gameSpeed, setGameSpeed] = useState(0.82);
    const initialWidth = document.body.clientWidth;
    const initialHeight = document.body.clientHeight;
    const targetGrid = [
        [100, 100, 100, 100, 300, 100, 100, 100, 100],
        [100, 100, 100, 100, 200, 100, 100, 100, 100],
        [100, 100, 100, 200, 200, 200, 100, 100, 100],
        [100, 100, 100, 200, 200, 200, 100, 100, 100],
        [100, 100, 200, 200, 200, 200, 200, 100, 100],
        [100, 200, 200, 200, 200, 200, 200, 100, 100],
        [100, 200, 200, 200, 200, 200, 200, 200, 100],
        [100, 200, 200, 200, 200, 200, 200, 200, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100]
    ]

    let currentAnimation = null;
    let lottieAnim;
    let lottieAnim2;
    let audioUrl = './sfx/level_1/Fishing_Boat_at_Sea_v4.mp3';

    React.useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation1,
            autoplay: false,
            loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function () {
            lottieAnim.play();
            document.getElementById('loader-init').style.display = "none";
            setGameStateCheck(true);
            if (currentSession.gameState) {
                setTimer(currentSession.gameState.remainingTime);
                setScore(currentSession.gameState.totalPoints);
                // setCaughtSymbols(currentSession.gameState.opened);
            }
            setTimeout(() => {
                setControlState({ ...controlState, isRunning: true });
                if (grinchAnimationContainer.current !== null) {
                    grinchAnimationContainer.current.style.opacity = 1;
                    grinchAnimationContainer.current.style.left = '65%';
                    grinchAnimationContainer.current.style.right = '11%';
                }
                refreshScene();
            }, 4800);
            ballRef.current.firstChild.style.top = `${animationContainer.current.offsetHeight * 0.74}px`;
            ballRef.current.firstChild.style.left = `${(window.innerWidth / 2) - ballRef.current.firstChild.offsetWidth / 2}px`;
            ballRef.current.firstChild.style.transition = 'opacity 0.5s ease-in-out 5.5s, top 0.5s, transform 0.7s cubic-bezier(0,0.5,0.7,1)';
            ballRef.current.firstChild.style.transform = 'scale(2) rotate(0deg)';
            ballRef.current.firstChild.style.opacity = 1;
        });

        const grinchAnim = Lottie.loadAnimation({
            container: grinchAnimationContainer.current,
            animationData: grinchanimation,
            autoplay: true,
            loop: true
        });

        lottieAnim2 = grinchAnim;
        setLottieAnim1(grinchAnim);
        lottieAnim2.play();
    }, [])

    React.useEffect(() => {
        isLoading(false);
    });

    const advanceStep = useCallback(() => {
        requestRef.current = requestAnimationFrame(advanceStep);
    }, [controlState.speed]);

    useEffect(() => {
        if (gameStateCheck) {
            setItterations(itterations + 1);
            okitiJelkuTimer({
                session: currentSession.id,
                caughtSymbols: rowCellArray,
            }).then(res => {
                if (res.gameState !== null && res.gameState.remainingTime <= 0) {
                    setScore(res.gameState.totalPoints);
                    // setTimer(res.gameState.remainingTime);
                    setControlState({ ...controlState, isRunning: false, sliderSpeed: 3 });
                    endScreen(res.gameState.totalPoints);
                } else if (res.gameState !== null) {
                    setDefendGoal(res.gameState.defend);
                    setScore(res.gameState.totalPoints);
                    setControlState({ ...controlState, sliderSpeed: res.gameState.speed });
                    if (!isMultiplierReady && !isMultiplierPlayed && res.gameState.multiplier) {
                        setMultiplierState(res.gameState.multiplier);
                        setIsMultiplierReady(true);
                    }
                    if (itterations === 1 && res.gameState.opened.length > 0) {
                        setTimeout(() => {
                            calculateSymbolPoints(res.gameState.opened);
                        }, 4900);
                    }
                }
            }).catch(err => {
                console.log(err);
                if (err.data == 'This session is already finished') {
                    setControlState({ ...controlState, isRunning: false });
                    handleNextLevel();
                } else if (err.data == 'Invalid game state') {
                    // setControlState({ ...controlState, isRunning: false });
                    // handleNextLevel();
                } else if (err.message == "Cannot read properties of undefined (reading 'remainingTime')") {
                    setControlState({ ...controlState, isRunning: false });
                }
            });
            setTimeout(() => {
                setRowCellArray([]);
            }, 100);
            setGameStateCheck(false);
        }
    }, [gameStateCheck, rowCellArray]);

    const decreaseTimer = useCallback(() => {
        setTimer((oldTimer) => {
            // console.log(oldTimer-1)
            setGameStateChecker(true);
            if (oldTimer > 0) {
                if (Math.round(oldTimer % 2) === 0) {
                    setGameStateCheck(true);
                }
                // const newTimer = currentSession.gameState.remainingTime;
                const newTimer = oldTimer - 1;
                // const newTimer = oldTimer - 1;
                // setTimer(currentSession.gameState.remainingTime);

                // const newTimer = timer;
                return newTimer;
            }
            setGameStateCheck(true);
            // setTimeout(() => {
            //     setControlState({ ...controlState, isRunning: false });
            //     endScreen(score);
            //     return 0;
            // }, 100);
        });
    }, [setTimer]);

    const positionElements = () => {
        const directInd = directionIndicator.current;
        if (directInd) {
            const directionParentWidth = directInd.parentNode.offsetWidth;
            // console.log(parseInt(directInd.style.left), directionParentWidth, directionParentWidth / 100 * 2); 
            // if (directInd.getAttribute('class') === "small-ball one-end") {
            if (parseInt(directInd.style.left) <= directionParentWidth / 100 * 5) {
                directInd.removeAttribute('style');
                // directInd.style.left = "83%";
                directInd.style.left = `${directionParentWidth / 100 * 85}px`;
                // directInd.setAttribute('class', 'small-ball other-end');
                grinchAnimationContainer.current.style.left = '65%';
            }
            // else if (directInd.getAttribute('class') === "small-ball other-end") {
            else if (parseInt(directInd.style.left) >= directionParentWidth / 100 * 75) {
                directInd.removeAttribute('style');
                // directInd.style.left = "2%";
                directInd.style.left = `${directionParentWidth / 100 * 2}px`;
                // directInd.setAttribute('class', 'small-ball one-end');
                grinchAnimationContainer.current.style.left = '3%';
            }
            // directInd.parentNode.addClass(`anim${controlState.sliderSpeed}`);
            directInd.parentNode.setAttribute('class', `slider horizontal anim${controlState.sliderSpeed}`);
            directInd.parentNode.style.width = '33%';
            directInd.parentNode.style.height = '10%';
            directInd.style.width = '34%';
            directInd.style.top = '0';
        }
        const powerInd = powerIndicator.current;
        if (powerInd) {
            const powerParentHeight = powerInd.parentNode.offsetHeight;
            // if (powerInd.getAttribute('class') === "small-ball one-end") {
            if (parseInt(powerInd.style.top) <= powerParentHeight / 100 * 5) {
                powerInd.removeAttribute('style');
                powerInd.style.top = `${powerParentHeight / 100 * 85}px`;
                // powerInd.style.top = "83%";
                // powerInd.setAttribute('class', 'small-ball other-end');
                grinchAnimationContainer.current.style.left = '3%';
            }
            // else if (powerInd.getAttribute('class') === "small-ball other-end") {
            else if (parseInt(powerInd.style.top) >= powerParentHeight / 100 * 75) {
                powerInd.removeAttribute('style');
                powerInd.style.top = `${powerParentHeight / 100 * 2}px`;
                // powerInd.style.top = "2%";
                // powerInd.setAttribute('class', 'small-ball one-end');
                grinchAnimationContainer.current.style.left = '65%';
            }
            // powerInd.parentNode.addClass(`anim${controlState.sliderSpeed}`);
            powerInd.parentNode.setAttribute('class', `slider vertical anim${controlState.sliderSpeed}`);
            powerInd.parentNode.style.width = '10%';
            powerInd.parentNode.style.height = '25%';
            powerInd.style.width = '45%';
            powerInd.style.left = '0%';
        }
    }

    useEffect(() => {
        positionTimerRef.current && clearInterval(positionTimerRef.current);
        if (controlState.sliderSpeed === 1 && controlState.isRunning) {
            // positionTimerRef.current && clearInterval(positionTimerRef.current);
            positionTimerRef.current = setInterval(positionElements, SPAWN_INTERVAL);
        } else if (controlState.sliderSpeed === 2 && controlState.isRunning) {
            // positionTimerRef.current && clearInterval(positionTimerRef.current);
            positionTimerRef.current = setInterval(positionElements, 650);
        } else if (controlState.sliderSpeed > 2 && controlState.isRunning) {
            positionTimerRef.current && clearInterval(positionTimerRef.current);
        }
    }, [controlState.isRunning, controlState.sliderSpeed]);

    const refreshScene = () => {
        // restart the meters
        if (powerIndicator.current !== null) {
            // powerIndicator.current.setAttribute('class', 'small-ball one-end');
            const powerParentHeight = powerIndicator.current.parentNode.offsetHeight;
            powerIndicator.current.style.top = `${powerParentHeight / 100 * 2}px`;
            // powerIndicator.current.style.top = `${(window.innerWidth / window.innerHeight) * 3}px`;
            powerIndicator.current.parentNode.style.opacity = 0;
        }
        if (directionIndicator.current !== null) {
            // directionIndicator.current.setAttribute('class', 'small-ball one-end');
            // directionIndicator.current.style.left = `${(window.innerWidth / window.innerHeight) * 3}px`;
            const directionParentWidth = directionIndicator.current.parentNode.offsetWidth;
            directionIndicator.current.style.left = `${directionParentWidth / 100 * 2}px`;
            directionIndicator.current.parentNode.style.opacity = 1;
        }
        setStateCheck(false);
        setSlotIndex(0);
    }

    // const pauseGame = () => {
    //     if (controlState.isRunning)
    //         setControlState({ ...controlState, isRunning: false });
    //     else
    //         setControlState({ ...controlState, isRunning: true });
    // }

    const intersection = (r1, r2) => {
        const xOverlap = Math.max(0, Math.min(r1.x + r1.w, r2.x + r2.w) - Math.max(r1.x, r2.x));
        const yOverlap = Math.max(0, Math.min(r1.y + r1.h, r2.y + r2.h) - Math.max(r1.y, r2.y));
        const overlapArea = xOverlap * yOverlap;

        return overlapArea;
    }

    const percentInView = (div, et, el) => {
        const rect = div.getBoundingClientRect();
        // console.log(ballRef.current.firstChild.style.transform.match(/scale\((.*)\)/)[1]);
        const dimension = { x: rect.x, y: rect.y, w: rect.width, h: rect.height };
        const viewport = { x: 0, y: 0, w: window.innerWidth, h: window.innerHeight };
        const element = { x: el, y: et, w: ballRef.current.firstChild.offsetWidth * 0.8, h: ballRef.current.firstChild.offsetHeight * 0.8 }
        const divsize = dimension.w * dimension.h;
        const overlap = intersection(dimension, element);

        return overlap / divsize;
    }

    const placeElementInFirstAvailableRowCell = (rowCell) => {
        let firstAvailable = false;
        if (rowCell !== [0, 0]) {
            for (let i = 0; i < hitGrid.current.children.length; i++) {
                for (let j = 0; j < hitGrid.current.children[i].children.length; j++) {
                    let arr = [i + 1, j + 1];
                    if (rowCell.toString() == arr.toString() && hitGrid.current.children[i].children[j].firstChild.style.opacity != 1 && hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value > 100) {
                        if (hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value === "300") {  
                            hitGrid.current.children[i].children[j].firstChild.src = 'images/game/star.png';                          
                            hitGrid.current.children[i].children[j].firstChild.style.transform = "scale(1)";
                        } else {
                            hitGrid.current.children[i].children[j].firstChild.src = 'images/game/ball.png';                          
                            hitGrid.current.children[i].children[j].firstChild.style.transform = `scale(0.7) rotate(-${Math.random() * 60 + 30}deg)`;
                        }
                        hitGrid.current.children[i].children[j].firstChild.style.opacity = 1;
                        setRowCellArray([i + 1, j + 1]);
                        // calculateSymbolPoints([i + 1, j + 1]);
                    } else if (rowCell.toString() == arr.toString() && hitGrid.current.children[i].children[j].firstChild.style.opacity == 1 && hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value > 100) {
                        for (let i = 0; i < hitGrid.current.children.length; i++) {
                            for (let j = 0; j < hitGrid.current.children[i].children.length; j++) {
                                if (hitGrid.current.children[i].children[j].firstChild.style.opacity == 0 && hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value > 100 && !firstAvailable) {
                                    if (hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value !== "300") {  
                                        hitGrid.current.children[i].children[j].firstChild.src = 'images/game/ball.png';
                                        hitGrid.current.children[i].children[j].firstChild.style.transform = `scale(0.7) rotate(-${Math.random() * 60 + 30}deg)`;
                                        hitGrid.current.children[i].children[j].firstChild.style.opacity = 1;
                                        firstAvailable = true;
                                        setRowCellArray([i + 1, j + 1]);
                                    }
                                    // hitGrid.current.children[i].children[j].firstChild.style.transform = `scale(0.6) rotate(-${Math.random() * 60 + 30}deg)`;
                                    // calculateSymbolPoints([i + 1, j + 1]);
                                }
                            }
                        }

                    }
                }
                // setRowCellArray(rowCell);
            }
        }
    }

    const shootTheBall = (d, p) => {
        // Calculate the ending position of the ball
        const ballImg = ballRef.current.getElementsByTagName('img');
        let Et;
        let El;
        let bestGuess = 0;
        let bestValue = 0;
        let bestGuessElement;
        let bestRowCell = [0, 0];
        // Et = window.innerHeight * p;
        Et = animationContainer.current.offsetHeight * p;
        let stringEt = Et.toString(10);
        // El = animationContainer.current.offsetWidth * p;
        El = window.innerWidth * d;
        let stringEl = El.toString(10);

        for (let i = 0; i < hitGrid.current.children.length; i++) {
            for (let j = 0; j < hitGrid.current.children[i].children.length; j++) {
                let tt = percentInView(hitGrid.current.children[i].children[j], Et, El) * 100;
                if (tt > bestGuess) {
                    bestGuess = tt;
                    bestValue = hitGrid.current.children[i].children[j].attributes.getNamedItem("data-value").value;
                    bestGuessElement = hitGrid.current.children[i].children[j];
                    bestRowCell = [i + 1, j + 1];
                }
            }
        }
        if (bestValue > 100 && defendGoal) {
            grinchAnimationContainer.current.style.transition = 'opacity 0.5s ease-in-out 0.5s, transform 0.25s cubic-bezier(0,0.6,0.8,1), left 0.25s cubic-bezier(0,0.6,0.8,1), top 0.15s cubic-bezier(0,0.6,0.8,1)';
            grinchAnimationContainer.current.style.top = `${Et}px`;
            grinchAnimationContainer.current.style.left = `${El}px`;
            ballImg[0].style.top = `${Et}px`;
            ballImg[0].style.left = `${El}px`;
            ballImg[0].style.transition = 'opacity 0.3s ease-in-out 0.2s, top 0.3s, transform 0.5s cubic-bezier(0,0.5,0.7,1)';
            ballImg[0].style.transform = `scale(0.75) rotate(-${Math.random() * 30 + 30}deg)`;
            ballImg[0].style.opacity = 0.35;
            if (grinchAnimationContainer.current.offsetLeft > El) {
                grinchAnimationContainer.current.style.transform = `scale(1.2) rotate(${Math.random() * 5 + 10}deg) translate(0%, -20%)`;
            } else {
                grinchAnimationContainer.current.style.transform = `scale(1.2) rotate(-${Math.random() * 5 + 10}deg) translate(0%, -50%)`;
            }
            setTimeout(() => {
                grinchAnimationContainer.current.style.transition = 'left 1.2s linear, right 1.2s linear, top 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s linear';
                grinchAnimationContainer.current.style.top = '68%';
                grinchAnimationContainer.current.style.left = '30%';
                grinchAnimationContainer.current.style.transform = "scale(1) rotate(0deg) translateY(-65%)";
                ballImg[0].style.transform = `scale(1.75) rotate(-${Math.random() * 30 + 30}deg)`;
                ballImg[0].style.opacity = 0;
            }, 250);
        } else if (bestValue > 100 && isMultiplierReady && !isMultiplierPlayed && new URL(ballImg[0].src).pathname === '/images/game/star.png') {
            placeElementInFirstAvailableRowCell([1,5]);
            setMultiplierState(false);
            setIsMultiplierReady(false);
            setIsMultiplierPlayed(true);
        } else if (bestValue > 100 && bestValue < 300 && !defendGoal) {
            placeElementInFirstAvailableRowCell(bestRowCell);
        } else {
            setRowCellArray([0, 0]);
            // calculateSymbolPoints([0, 0]);
        }
        ballImg[0].style.top = `${Et}px`;
        ballImg[0].style.left = `${El}px`;
        ballImg[0].style.transition = 'opacity 0.5s ease-in-out 0.2s, top 0.4s, transform 0.6s cubic-bezier(0,0.5,0.7,1)';
        ballImg[0].style.transform = `scale(0.75) rotate(-${Math.random() * 30 + 30}deg)`;
        ballImg[0].style.opacity = 0;
        setTimeout(() => {
            lottieAnim1.pause();
        }, 100);
        setTimeout(() => {
            lottieAnim1.play();
        }, 750);

        setTimeout(() => {
            setStateCheck(true);
            if (isMultiplierReady && !isMultiplierPlayed && new URL(ballImg[0].src).pathname !== '/images/game/star.png') {
                ballImg[0].src = 'images/game/star.png';
                ballImg[1].style.opacity = 1;
                // console.log('multiplier is on', new URL(ballImg[0].src).pathname);
            } else {
                ballImg[0].src = 'images/game/ball.png';
                ballImg[1].style.opacity = 0;
            }

            if (animationContainer.current) {
                ballImg[0].style.top = `${animationContainer.current.offsetHeight * 0.74}px`;
                ballImg[0].style.left = `${(window.innerWidth / 2) - ballImg[0].offsetWidth / 2}px`;
                ballImg[0].style.transform = 'scale(2) rotate(0deg)';
                ballImg[0].style.opacity = 1;
            }
            refreshScene();
            // setGameStateCheck(false);
            // setControlState({ ...controlState, isRunning: true });
        }, 1000);

    }

    const stopHorizontalCircle = () => {
        const style = window.getComputedStyle(directionIndicator.current);
        const left = style.getPropertyValue('left');
        let x2 = 0;

        x2 = parseInt(left.substring(0, 3), 10);
        x2 = x2 / (directionIndicator.current.parentNode.offsetWidth - (directionIndicator.current.offsetWidth / 2)) / 1.05;

        if (x2 > 0.45 && x2 < 0.55) {
            x2 = 0.5;
        } else if (x2 > 0.3 && x2 < 0.45) {
            x2 = 0.45;
        } else if (x2 > 0.55 && x2 < 0.7) {
            x2 = 0.55;
        }
        setDirection(x2);
        setSlotIndex(slotIndex + 1);
        // fix the position of the small ball to wherever it is
        directionIndicator.current.setAttribute("class", "small-ball");
        directionIndicator.current.style.left = left;
        powerIndicator.current.parentNode.style.opacity = 1;
        directionIndicator.current.parentNode.style.opacity = 0;
    }

    const stopVerticalCircle = () => {
        const style = window.getComputedStyle(powerIndicator.current);
        const top = style.getPropertyValue('top');
        let y2 = 0;
        y2 = parseInt(top.substring(0, 3), 10);
        y2 = y2 / (powerIndicator.current.parentNode.offsetHeight - (powerIndicator.current.offsetHeight / 2));
        if (y2 > 0.45 && y2 < 0.55) {
            y2 = 0.5;
        } else if (y2 > 0.35 && y2 < 0.45) {
            y2 = 0.45;
        } else if (y2 > 0.55 && y2 < 0.7) {
            y2 = 0.55;
        } else if (y2 > 0.2 && y2 < 0.4) {
            y2 = 0.32;
        } else if (y2 > 0.55) {
            y2 = y2/1.4;
        }
        setSlotIndex(slotIndex + 1);
        // fix the position of the small ball to wherever it is
        powerIndicator.current.setAttribute("class", "small-ball");
        powerIndicator.current.style.top = top;
        setPower(y2);
        shootTheBall(direction, y2);
        directionIndicator.current.parentNode.style.opacity = 1;
        // setGameStateCheck(true);
        // setControlState({ ...controlState, isRunning: false });
    }

    // useEffect(() => {
    //     window.addEventListener("beforeunload", alertUser);
    //     return () => {
    //       window.removeEventListener("beforeunload", alertUser);
    //     };
    //   }, []);
    //   const alertUser = (e) => {
    //     e.preventDefault();
    //     e.returnValue = "";
    // };

    useEffect(() => {
        const stop = () => {
            // intervalRef.current && clearInterval(intervalRef.current);
            timerRef.current && clearInterval(timerRef.current);
            requestRef.current && cancelAnimationFrame(requestRef.current);
        }

        if (controlState.isRunning) {
            // intervalRef.current = setInterval(spawnDot, SPAWN_INTERVAL);
            setGameStateCheck(true);
            setTimeout(() => {
                timerRef.current = setInterval(decreaseTimer, SPAWN_INTERVAL);
            }, 200);
            // positionTimerRef.current = setInterval(positionElements, 1000);
            requestRef.current = requestAnimationFrame(advanceStep);
        } else {
            stop();
        }
        // return () => stop();
    }, [controlState.isRunning, advanceStep])

    const clear = useCallback(() => {
        setControlState({ ...controlState, isRunning: false, speed: SPEED_STEP });
        // setScore(0);
        setTimer(0);
    }, [setControlState, setScore, controlState]);

    const calculateSymbolPoints = (caughtArr) => {
        for (let i = 0; i < caughtArr.length; i++) {   
            console.log(caughtArr[i][0] === 0 && caughtArr[i][1] === 4, caughtArr[i]);
            if (caughtArr[i][0] === 0 && caughtArr[i][1] === 4) {                            
                hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.src = 'images/game/star.png';
                hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.style.transform = "scale(1)";
            } else {
                hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.src = 'images/game/ball.png';
                hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.style.transform = `scale(0.6) rotate(-${Math.random() * 30 + 30}deg)`;
            }
            // hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.src = 'images/game/ball.png';
            hitGrid.current.children[caughtArr[i][0]].children[caughtArr[i][1]].firstChild.style.opacity = 1;
        }
    }

    return (
        <div className="main" key="game-src-desktop">
            <div className="game-scr-animation" ref={animationContainer}>
                <div className="slider horizontal">
                    <div ref={directionIndicator} className="small-ball one-end">
                    </div>
                </div>
                <div className="slider vertical">
                    <div ref={powerIndicator} className="small-ball one-end">
                    </div>
                </div>
                <div className="hitting-grid container" ref={hitGrid}>
                    {targetGrid.map((gridElement, index) => {
                        // console.log(gridElement, index);
                        return <>
                            <div className="grid-row row" data-number={index} ref={gridRow}>
                                {gridElement.map((j, i) => {
                                    return <>
                                        <div className="grid-cell" data-number={i} data-value={j} ref={gridCell}>
                                            {j === 100 && animationContainer.current && <img src={'images/game/finger.png'} width="100%" />}
                                            {j === 200 && animationContainer.current && <img src={'images/game/circle.png'} width="100%" />}
                                            {j === 300 && animationContainer.current && <img src={'images/game/star.png'} width="100%" />}
                                        </div>
                                    </>;
                                })}
                            </div>
                        </>
                    })}
                </div>
                <div className="grinch-animation" ref={grinchAnimationContainer} />
                <div className="ball" ref={ballRef}>
                    <img src='images/game/ball.png' />
                    <img className="x2" src='images/game/x2.png' />
                </div>
            </div>
            <div className="panel">
                <Control onClear={clear} />
                <Score />
                <Timer />
            </div>
            {slotIndex === 0 &&
                <div className='game_btn horizontal' onMouseDown={stopHorizontalCircle} style={{ top: `${animationContainer.current && animationContainer.current.offsetHeight * 0.85}px` }}>
                    <img src='./images/game/pogodi_pravac.png' alt='Podesi horizontalno' width="55%" />
                </div>}
            {slotIndex === 1 && <div className='game_btn vertical' onMouseDown={stopVerticalCircle} style={{ top: `${animationContainer.current && animationContainer.current.offsetHeight * 0.85}px` }}>
                <img src='./images/game/pogodi_jacinu.png' alt='Podesi vertikalno' width="55%" />
            </div>}
            {/* <div className="pause" onClick={pauseGame}>
                <img src='images/game/pogodi_pravac.png' />
            </div> */}
        </div>
    );
};
